



























































import Vue, { VueConstructor } from 'vue';
import OwnerOrderService from '@/services/admin/AdminOrderService';
import DataTable from '@/components/shared/DataTable.vue';
import OrderStatus from '@/components/orders/OrderStatus.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { DataTable, OrderStatus },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminOrderIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      orders: [],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'start', asc: false },
      currentPage: 1,
      loading: false,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadcrumb();
  },
  methods: {
    setBreadcrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminOrderIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      OwnerOrderService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res: any) => {
        this.loading = false;
        this.orders = res.value.orders.items;
        this.pages = res.value.orders.totalPages;
      });
    },
  },
});

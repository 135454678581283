import ApiService from '@/services/ApiService';

const API_URL = '/api/admin/Order/';

class OwnerOrderService {
  getDetail(orderId: string) {
    return ApiService.get(`${API_URL}Details?orderId=${orderId}`);
  }

  cancelOrder(orderId: any, adminMessage: string) {
    return ApiService.post(`${API_URL}Cancel`, { orderId, adminMessage });
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.post(`${API_URL}Index`, { currentPage, sortColumn, sortAsc, tableFilter });
  }
}

export default new OwnerOrderService();

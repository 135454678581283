






import Vue from 'vue';

export default Vue.extend({
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    color() {
      switch (this.status) {
        case 'Confirmed':
        case 'Completed':
          return 'success';
        case 'Prepared':
        case 'Pending':
          return 'warning';
        case 'Rejected':
          return 'secondary';
        case 'Cancelled':
        case 'Expired':
          return 'danger';
        default:
          return '';
      }
    },
  },
});
